import Vue from 'vue';
import iview from 'iview';
import axios from 'axios';
import router from '../../router';
import moment from 'moment';
import api from '@zhima/api';
Vue.http = axios;

import { postRestbV3Api } from '../../api/api';
var http_build_query = function (obj, num_prefix, temp_key) {
    var output_string = [];
    Object.keys(obj).forEach(function (val) {
        var key = val;
        num_prefix && !isNaN(key) ? (key = num_prefix + key) : '';
        var key = encodeURIComponent(key.replace(/[!'()*]/g, escape));
        temp_key ? (key = temp_key + '[' + key + ']') : '';
        if (typeof obj[val] === 'object') {
            var query = http_build_query(obj[val], null, key);
            output_string.push(query);
        } else {
            if (typeof obj[val] === 'undefined') {
                output_string.push(key + '=');
            } else if (typeof obj[val] === 'number') {
                output_string.push(key + '=' + obj[val]);
            } else {
                var value = encodeURIComponent(obj[val].replace(/[!'()*]/g, escape));
                output_string.push(key + '=' + value);
            }
        }
    });
    return output_string.join('&');
};

// Vue.use(Vuex)
// 对象类型
const taskType = {
    0: '全国',
    1: '省份',
    2: '城市',
    4: '门店',
    7: '区域',
    9: '设备',
};
var error_report = 0;
// 维度类型
const dimensionType = {
    1: '自然日',
    2: '自然周',
    3: '自然月',
};

// 群组类型
// const groupType = {

//     1: '省份',
//     2: '城市',
//     4: '门店',
//     5: '区域',
//     6: '门店区域'
// }
const groupType = {
    0: '全国',
    1: '省份',
    2: '城市',
    4: '门店',
    7: '区域',
    9: '设备',
};

// 导出类型
const exportStatus = {
    0: '已经提交',
    1: '正在执行',
    2: '执行成功',
    3: '执行失败',
};

// 门店筛选组件选择类型生成
const storesType = [
    {
        value: 0,
        label: '查看全国',
    },
    {
        value: 1,
        label: '查看省份',
    },
    {
        value: 2,
        label: '查看城市',
    },
    {
        value: 4,
        label: '查看门店',
    },
    {
        value: 7,
        label: '查看区域',
    },
];

// 真 四舍五入
const toFixed = (value, num = 2) => {
    let _newValue;
    let _arr = String(value).split('.');

    // 如果是整数则直接补零并返回
    if (_arr.length == 1) {
        _newValue = Number(value).toFixed(num);
    } else {
        let _integerSection = _arr[0]; // 整数部分
        let _decimalSection = _arr[1].substring(0, num); // 小数部分
        const _tail = _arr[1].substring(num, Math.round(num + 1)); // 小数部分的后一位
        if (_tail > 4) {
            // 当小数部分全部为9时，整数部分进1，小数部分全部为0 否则正常小数部分加一
            if (_decimalSection.split('').every((el) => el == '9')) {
                _integerSection = Math.round(Number(_integerSection) + 1);
                _decimalSection = '0'.padStart(num, '0');
            } else {
                _decimalSection = String(Math.round(Number(_decimalSection) + 1)).padStart(num, '0'); // 判断是否需要四舍五入
            }
        }
        _newValue = Number(`${_integerSection}.${_decimalSection}`).toFixed(num); // 位数不足补零
    }
    return _newValue;
};

const isInteger = (obj) => {
    return Math.floor(obj) === obj;
};
const toInteger = (floatNum) => {
    var ret = { times: 1, num: 0 };
    //是整数
    if (isInteger(floatNum)) {
        ret.num = floatNum;
        return ret;
    }
    var strfi = floatNum + '';
    //查找小数点的下标
    var dotPos = strfi.indexOf('.');
    // console.log('dotPos===='+dotPos);
    //获取小数的位数
    var len = strfi.substr(dotPos + 1).length;
    // console.log('len===='+len);
    //Math.pow(10,len)指定10的len次幂。
    var time = Math.pow(10, len);

    //将浮点数转化为整数
    var intNum = parseInt(floatNum * time + 0.5, 10);
    // console.log('intNum===='+intNum);
    ret.times = time;
    ret.num = intNum;
    return ret;
};
const operation = (aaa, bbb, op) => {
    var o1 = toInteger(aaa);
    var o2 = toInteger(bbb);
    var n1 = o1.num;
    var n2 = o2.num;
    var t1 = o1.times;
    var t2 = o2.times;
    var max = t1 > t2 ? t1 : t2;
    var result = null;
    switch (op) {
        case 'add':
            if (t1 === t2) {
                result = n1 + n2;
            } else if (t1 > t2) {
                result = n1 + n2 * (t1 / t2);
            } else {
                result = n1 * (t2 / t1) + n2;
            }

            return result / max;
            break;
        case 'sub':
            if (t1 === t2) {
                result = n1 - n2;
            } else if (t1 > t2) {
                result = n1 - n2 * (t1 / t2);
            } else {
                result = n1 * (t2 / t1) - n2;
            }

            return result / max;
            break;
        case 'mul':
            result = (n1 * n2) / (t1 * t2);
            return result;
            break;
        case 'div':
            result = n1 / n2 / (t2 / t1);
            return result;
            break;
    }
};

export default class Common {
    // 判断字符串是不是大于传进来的字符长度
    static judeStringLength(str, len) {
        if (str.length > len) {
            return str.substring(0, len) + '...';
        } else {
            return str;
        }
    }

    // 两位小数正则
    static inputNumberReg(val, num = 2) {
        let reg = '';
        if (val && val !== 0) {
            if (num === 2) {
                reg = val
                    .replace(/[^\d.]/g, '')
                    .replace('.', '$#$')
                    .replace(/\./g, '')
                    .replace('$#$', '.')
                    .toString()
                    .match(/^\d+(?:\.\d{0,2})?/);
            } else {
                reg = val
                    .replace(/[^\d.]/g, '')
                    .replace('.', '$#$')
                    .replace(/\./g, '')
                    .replace('$#$', '.')
                    .toString()
                    .match(/^\d+(?:\.\d{0,1})?/);
            }
        }
        if (!reg) return '';
        return reg[0];
    }
    // 最大是还能输入7位整数 两位小数
    static inputNumberMaxSeven(value) {
        // 翻倍大于等于1/第一位不为..slice(0,1)
        value = value.replace(/[^\d.]/g, ''); //清除“数字”和“.”以外的字符
        value = value.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的
        value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入2个小数
        // 判断是否有小数点取7位整数
        if (value.indexOf('.') == -1) {
            value = value.slice(0, 7);
        } else if (value.indexOf('.') != -1) {
            value = value;
        }
        if (value.indexOf('.') < 0 && value != '') {
            //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
            value = parseFloat(value);
        }
        return value;
    }

    // 上传文件
    static uploadImage(event, size, type, text, prompt) {
        return new Promise((resolve) => {
            let obj = {
                img_name: '',
                img_url: '',
                img_base64: '',
            };
            let file = event.target.files[0];
            if (!file) return false;
            let sizeStatus = this.judgmentSize(file, size, type, text, prompt);
            if (!sizeStatus) {
                event.target.value = '';
                resolve(false);
                return;
            }
            let reader = new FileReader();
            reader.onloadend = () => {
                obj.img_base64 = reader.result;
            };
            reader.readAsDataURL(file);
            let params = new FormData();
            params.append('file', file);
            if (type && type.includes('xls')) {
                if (type.includes('sendcoupon')) {
                    this.$postMallGrantMembersImport(resolve, params, file);
                } else {
                    this.$postMallItemsSkus(resolve, params, file);
                }
            } else {
                if (size > 2) {
                    params.append('limit', size * 1024 * 1024);
                }
                this._postsend('/restful/common/file', params)
                    .then((res) => {
                        obj.img_name = file.name;
                        obj.img_url = res.data.data.url;
                        resolve(obj);
                    })
                    .catch((error) => {
                        resolve(false);
                        this.httpStatusAction(error);
                    });
            }
        });
    }

    // Excel模板
    static $postMallItemsSkus(resolve, params, file) {
        api.postMallItemsSkus({ mall_id: $.cookie('mall_id') }, {}, params).then((res) => {
            if (res.code === 200) {
                let data = res.data;
                data.name = file.name;
                resolve(data);
            } else {
                resolve(false);
                iview.Message.error(res.msg);
            }
        });
    }
    // Excel模板
    static $postMallGrantMembersImport(resolve, params, file) {
        api.postMallGrantMembersImport({ mall_id: $.cookie('mall_id') }, {}, params).then((res) => {
            if (res.code === 200) {
                let data = res.data;
                data.name = file.name;
                data.file = params;
                resolve(data);
            } else {
                resolve(false);
                iview.Message.error(res.msg);
            }
        });
    }
    // 验证文件大小
    static judgmentSize(file, max, type = 'jpg, jpeg, png, gif', text, prompt = '文件类型错误！') {
        if (!file.name) return false;
        let index = file.name.lastIndexOf('.');
        let ext = file.name.substr(index + 1).toLowerCase();
        if (!type.includes(ext)) {
            iview.Message.error({
                content: prompt,
                duration: 3,
            });
            return false;
        }
        let fileMaxSize = max * 1024;
        let fileSize = file.size / 1024;
        if (fileSize > fileMaxSize || fileSize <= 0) {
            file.value = '';
            if (max < 1) {
                iview.Message.error({
                    content: '所选' + (text || '图片') + '不能超过' + fileMaxSize + 'KB',
                    duration: 3,
                });
            } else {
                iview.Message.error({
                    content: '所选' + (text || '图片') + '不能超过' + max + 'M',
                    duration: 3,
                });
            }
            return false;
        }
        return true;
    }

    static getSelectMore(id) {
        // let params = {}
        // params['parent_id']= id;
        // return Vue.http.get(('/api/v1/group/get-by-parent-id'), {
        //     params: params
        // }).then((resp) => {
        // if (resp.body.code !== 1000) {
        //     throw new Error(resp.body.msg)
        // }
        // return Promise.resolve(resp.body)
        // }, (resp) => {
        //     alert("失败了")
        //     // return Promise.reject(resp.status);
        // })
    }

    // 返回对象类型
    static toType(type) {
        return taskType[type];
    }

    // 返回维度类型
    static toDimension(type) {
        return dimensionType[type];
    }

    // 返回群组类型
    static toGroupType(type) {
        return groupType[type];
    }

    // 返回导出状态
    static toExportStatus(status) {
        return exportStatus[status];
    }

    //开启loading
    static openLoading(myChart) {
        var opts = {
            text: '',
            color: '#0097FF',
            textColor: '#000',
            maskColor: 'none',
            zlevel: 0,
        };
        myChart.showLoading(opts);
    }

    //关闭loading
    static closeLoading(myChart) {
        myChart.hideLoading();
    }

    static httpBuildUrl(url, data) {
        return url + '?' + http_build_query(data);
    }
    // 统一AJAX new_mall_id集团版选择商场是切换mall_id
    static _send(url, data, new_mall_id) {
        if (!data) {
            if (url != '/api/user/info') {
                data = {
                    mall_id: new_mall_id ? new_mall_id : $.cookie('mall_id'),
                };
            } else {
                data = {};
            }
        } else if (url == '/api/dashboard/real_time_tag') {
            data.mall_id = 1325;
        } else {
            if (url != '/restful/malls/locations') {
                data.mall_id = new_mall_id ? new_mall_id : $.cookie('mall_id');
            }
        }
        data.data_source = $.cookie('data_source');

        return Vue.http
            .get(url, {
                params: data,
                headers: {
                    accept: 'application/json',
                    Authorization: 'Bearer ' + $.cookie('access_token') || '',
                },
            })
            .then(
                (resp) => {
                    if (resp.status !== 200) {
                        // throw new Error(resp)
                        return Promise.reject(resp);
                    }
                    if (resp.data.code === 40101) {
                        //初始密码需要修改
                        router.push({
                            path: '/setNewPassword',
                            query: {
                                origin: true,
                            },
                        });
                    } else if (resp.data.code === 40102) {
                        //强制需要修改密码
                        router.push({
                            path: '/setNewPassword',
                        });
                    }
                    return Promise.resolve(resp);
                },
                (resp) => {
                    if (resp.response.status == 401) {
                        $.removeCookie('access_token');
                        $.removeCookie('winshang_uid');
                    }
                    return Promise.reject(resp);
                }
            );
    }

    static _postsendurl(url, params1) {
        let isFormData = (v) => {
            return Object.prototype.toString.call(v) === '[object FormData]';
        };

        if (url != '/api/login') {
            if (!params1) {
                params1 = {
                    mall_id: $.cookie('mall_id'),
                };
            } else {
                if (isFormData(params1) && (params1.get('mall_id') == '' || params1.get('mall_id') == null)) {
                    params1.append('mall_id', $.cookie('mall_id'));
                } else {
                    params1.mall_id = $.cookie('mall_id');
                }
            }
        }

        return Vue.http
            .post(
                url,
                params1,

                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // accept: 'application/x-www-form-urlencoded',
                        Authorization: 'Bearer ' + $.cookie('access_token') || '',
                    },
                }
            )
            .then(
                (resp) => {
                    if (resp.status != 200) {
                        return Promise.reject(resp);
                    }
                    if (resp.data.code === 40101) {
                        //初始密码需要修改
                        router.push({
                            path: '/setNewPassword',
                            query: {
                                origin: true,
                            },
                        });
                    } else if (resp.data.code === 40102) {
                        //强制需要修改密码
                        router.push({
                            path: '/setNewPassword',
                        });
                    }
                    return Promise.resolve(resp);
                },
                (resp) => {
                    if (resp.response.status == 401) {
                        $.removeCookie('access_token');
                        $.removeCookie('winshang_uid');
                    }
                    return Promise.reject(resp);
                }
            );
    }

    static _postsend(url, params1) {
        let isFormData = (v) => {
            return Object.prototype.toString.call(v) === '[object FormData]';
        };

        if (url != '/api/login') {
            if (!params1) {
                params1 = {
                    mall_id: $.cookie('mall_id'),
                };
            } else {
                if (isFormData(params1) && (params1.get('mall_id') == '' || params1.get('mall_id') == null)) {
                    params1.append('mall_id', $.cookie('mall_id'));
                } else {
                    params1.mall_id = $.cookie('mall_id');
                }
            }
        }

        return Vue.http
            .post(
                url,
                params1,

                {
                    headers: {
                        // 'Content-Type':'application/x-www-form-urlencoded',
                        // accept: 'application/x-www-form-urlencoded',
                        Authorization: 'Bearer ' + $.cookie('access_token') || '',
                    },
                }
            )
            .then(
                (resp) => {
                    if (resp.status != 200) {
                        return Promise.reject(resp);
                    }
                    if (resp.data.code === 40101) {
                        //初始密码需要修改
                        router.push({
                            path: '/setNewPassword',
                            query: {
                                origin: true,
                            },
                        });
                    } else if (resp.data.code === 40102) {
                        //强制需要修改密码
                        router.push({
                            path: '/setNewPassword',
                        });
                    }
                    return Promise.resolve(resp);
                },
                (resp) => {
                    if (resp.response.status == 401) {
                        $.removeCookie('access_token');
                        $.removeCookie('winshang_uid');
                    }
                    return Promise.reject(resp);
                }
            );
    }

    static _put(url, params1) {
        return Vue.http
            .put(url, params1, {
                headers: {
                    // 'Content-Type':'application/x-www-form-urlencoded',
                    accept: 'application/x-www-form-urlencoded',
                    Authorization: 'Bearer ' + $.cookie('access_token') || '',
                },
            })
            .then(
                (resp) => {
                    if (resp.status != 200) {
                        return Promise.reject(resp);
                    }
                    return Promise.resolve(resp);
                },
                (resp) => {
                    return Promise.reject(resp);
                }
            );
    }

    static _del(url, params1) {
        return Vue.http
            .delete(url, {
                body: params1,
                headers: {
                    // 'Content-Type':'application/x-www-form-urlencoded',
                    accept: 'application/x-www-form-urlencoded',
                    Authorization: 'Bearer ' + $.cookie('access_token') || '',
                },
            })
            .then(
                (resp) => {
                    if (resp.status != 200) {
                        return Promise.reject(resp);
                    }
                    return Promise.resolve(resp);
                },
                (resp) => {
                    return Promise.reject(resp);
                }
            );
    }

    static makeDetailTick(detail) {
        let date = new Date();
        let tick = date.getDate();
        // $.cookie(`active-detail-${tick}`, JSON.stringify(detail), 10)
        localStorage.setItem(`active-detail-${tick}`, JSON.stringify(detail));
        return tick;
    }

    static retrieveDetail(tick) {
        let id = `active-detail-${tick}`;
        let data = localStorage.getItem(id);
        // let data = $.cookie(id)
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    // 保留两位小数
    static tofix(arr) {
        let teamp = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === '' || arr[i] === null || arr[i] === '-') {
                teamp.push('');
            } else {
                teamp.push(parseFloat(arr[i]).toFixed(2));
            }
        }
        return teamp;
    }

    // 保留1位小数
    static tofixone(arr) {
        let teamp = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === '' || arr[i] === null || arr[i] === '-') {
                teamp.push('');
            } else {
                teamp.push(parseFloat(arr[i]).toFixed(1));
            }
        }
        return teamp;
    }

    // 把秒转为分钟
    static tomin(arr) {
        var teamp = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === '') {
                teamp.push('');
            } else if (arr[i] === 0) {
                teamp.push(0.0);
            } else {
                teamp.push((parseFloat(arr[i]) / 60).toFixed(2));
            }
        }
        return teamp;
    }

    // 把秒转为分钟，并保留一位小数
    static tominone(arr) {
        var teamp = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === '') {
                teamp.push('');
            } else if (arr[i] === 0) {
                teamp.push(0.0);
            } else {
                teamp.push((parseFloat(arr[i]) / 60).toFixed(1));
            }
        }
        return teamp;
    }

    // 将一个数组的每一项乘100保留两位小数
    static torate(arr) {
        var teamp = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === '') {
                teamp.push('');
            } else if (arr[i] === 0) {
                teamp.push(0.0);
            } else {
                teamp.push((parseFloat(arr[i]) * 100).toFixed(2));
            }
        }
        return teamp;
    }

    // 将一个数组的每一项乘100保留1位小数
    static torateone(arr) {
        var teamp = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === '') {
                teamp.push('');
            } else if (arr[i] === 0) {
                teamp.push(0.0);
            } else {
                teamp.push((parseFloat(arr[i]) * 100).toFixed(1));
            }
        }
        return teamp;
    }

    static toint(arr) {
        var teamp = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === '') {
                teamp.push('');
            } else {
                teamp.push(parseInt(arr[i]));
            }
        }
        return teamp;
    }

    //根据日期算周几
    static fGetCurrentWeek(date) {
        var sWeek = moment(date).format('dddd');
        switch (sWeek) {
            case 'Monday':
                sWeek = '周一';
                break;
            case 'Tuesday':
                sWeek = '周二';
                break;
            case 'Wednesday':
                sWeek = '周三';
                break;
            case 'Thursday':
                sWeek = '周四';
                break;
            case 'Friday':
                sWeek = '周五';
                break;
            case 'Saturday':
                sWeek = '周六';
                break;
            case 'Sunday':
                sWeek = '周日';
                break;
            default:
                break;
        }
        return sWeek;
    }

    static httpStatusAction(resp) {
        const status = resp.status || resp.response.status;
        if (!status) return;
        if (status === 401) {
            if (error_report == 0) {
                iview.Message.error('登录超时，请重新登录！');
            }
            error_report = 1;
            $.removeCookie('access_token');
            $.removeCookie('username');

            let uid = $.cookie('id');
            $.removeCookie('access_token');
            $.removeCookie('company_name');
            $.removeCookie('email');
            $.removeCookie('user_name');
            $.removeCookie('phone');
            $.removeCookie('id');
            $.removeCookie('support_miniapp');
            $.removeCookie('company_address');
            $.removeCookie('company_mobile');
            $.removeCookie('mall_name');
            $.removeCookie('lat');
            $.removeCookie('lng');
            $.removeCookie('mall_id');
            $.removeCookie('mall_count');
            $.removeCookie('role_id');
            $.removeCookie('name');
            $.removeCookie('menuIndex');
            $.removeCookie('winshang_uid');
            $.removeCookie('data_source');
            $.removeCookie('company_manager');
            $.removeCookie('before_mall' + uid);
            localStorage.removeItem('wxcoupon');
            router.replace('/login');
        } else if (status == 500) {
            if (error_report == 0) {
                iview.Message.error('服务器出错了！');
            }
            error_report = 1;
        } else if (status == 504) {
            if (error_report == 0) {
                iview.Message.error('网络链接超时！');
            }
            error_report = 1;
        } else {
            if (resp.response.data && resp.response.data.msg) {
                iview.Message.error(resp.response.data.msg);
            }
        }
    }

    static _storeSelect(data) {
        var type = [];
        data.forEach(function (item, j, data) {
            storesType.forEach(function (item, i, array) {
                if (data[j] == array[i].value) {
                    type.push(array[i]);
                } else {
                    return;
                }
            });
        });
        return type;
    }

    // *100 + %
    static numMultiply100(num) {
        if (!isNaN(num)) {
            return (num * 100).toFixed(2) + '%';
        } else {
            return '-';
        }
    }

    // 折线图峰值获取宽度
    static textSize(text, fontSize) {
        var span = document.createElement('span');
        var result = {
            width: span.offsetWidth,
            height: span.offsetHeight,
        };
        span.style.visibility = 'hidden';
        span.style.fontSize = fontSize || '14px';
        document.body.appendChild(span);

        if (typeof span.textContent != 'undefined') span.textContent = text || '';
        else span.innerText = text || '';

        result.width = span.offsetWidth - result.width;
        result.height = span.offsetHeight - result.height;
        span.parentNode.removeChild(span);
        return result;
    }

    // 格式化日期 yyyy-MM-dd
    static $formatDate(date, type = 'yyyy-mm-dd') {
        date = String(date).includes('GMT') ? date : new Date(date);
        let yy = date.getFullYear();
        let mm = (date.getMonth() + 1).toString();
        let dd = date.getDate().toString();
        let h = date.getHours().toString();
        let m = date.getMinutes().toString();
        let s = date.getSeconds().toString();
        let checkTime = (val) => {
            if (val < 10) {
                val = '0' + val;
            }
            return val;
        };

        mm = checkTime(mm);
        dd = checkTime(dd);
        h = checkTime(h);
        m = checkTime(m);
        s = checkTime(s);

        if (mm === '00') {
            yy = yy - 1;
            mm = '12';
        }
        if (type === 'yyyy-mm') {
            return yy + '-' + mm;
        }
        if (type === 'yyyy-mm-dd') {
            return yy + '-' + mm + '-' + dd;
        }
        if (type === 'yyyy-mm-dd h:m') {
            return yy + '-' + mm + '-' + dd + ' ' + h + ':' + m;
        }
        if (type === 'yyyy-mm-dd h:m:s') {
            return yy + '-' + mm + '-' + dd + ' ' + h + ':' + m + ':' + s;
        }
    }

    // 计算加减乘除并且获得指定位数的小数（解决浮点数问题，使用时需要将数据转为Number类型）
    static countNum(aaa, bbb, op, fixed, type = 'number') {
        let new_num = String(operation(Number(aaa), Number(bbb), op)),
            result = type == 'number' ? Number(toFixed(new_num, fixed)) : toFixed(new_num, fixed);
        return result;
    }

    // 获取微信外部链接
    static async postScheme(path, query, that, type, id) {
        let method = 'app.wechatShortUrl';
        let params = {
            mall_id: $.cookie('mall_id'),
            type: type,
            id: id,
            path: path,
            query: query,
        };
        let result = {};
        let res = await postRestbV3Api(method, params);
        if (res.code == 200) {
            that.exportModule = false;
            result = res.data;
        } else {
            that.$Message.destroy();
            this.httpStatusAction(res);
        }
        // let result = {},
        //     data = {};
        // if (path && query) {
        //     data = {
        //         path,
        //         query,
        //         mall_id: $.cookie('mall_id'),
        //     }
        // } else if (type && id) {
        //     data = {
        //         type,
        //         id,
        //         mall_id: $.cookie('mall_id'),
        //     }
        // }
        // let headerConfig = {
        //     'Content-Type': 'application/json',
        //     Authorization: 'Bearer ' + $.cookie('access_token') || '',
        // };
        // $.ajax({
        //     url: '/restb/wechat-mini/scheme',
        //     type: 'post',
        //     data: JSON.stringify(data),
        //     headers: Object.assign({}, headerConfig),
        //     dataType: 'json',
        //     async: false, //重点，async一定要设置为false，以保证返回数据后才进行下一步的操作
        //     success:(res) => {
        //         that.exportModule = false
        //         result = res;
        //     },
        //     fail:() => {
        //         that.$Message.destroy();
        //         $http.httpStatusAction(error);
        //     },
        // });
        console.log(result);
        return result;
    }

    // 将微信外链复制到剪切板
    static copyScheme(url, that) {
        let input = document.createElement('input');
        input.style.position = 'fixed';
        input.style.bottom = '-50px';
        input.value = url;
        document.body.appendChild(input);
        input.select();
        document.execCommand('Copy');
        that.$Message.success('复制成功');
    }

    static chooseShopMatch(ids, list, nameWithCenter = false) {
        let name = '',
            name_shop = '',
            choose_list = [],
            name_list = [];
        for (let i = 0, len = list.length; i < len; i++) {
            let item = list[i];
            if (ids.includes(item.id)) {
                choose_list.push(item);
                let name = item.name;
                if(nameWithCenter && item.center && Object.keys(item.center).length !== 0) {
                    name += '(' + item.center.name + ')';
                }
                name_list.push(name);
            }
        }
        if (name_list.length) {
            name = name_list[0];
            if(nameWithCenter) name = name.split('(')[0]
            if (name_list.length > 1) {
                name = name + ' 等' + name_list.length + '家';
                name_shop = name + '店铺';
            } else {
                name_shop = name;
            }
        }
        return {
            name,
            name_shop,
            choose_list,
            name_list,
        };
    }

    // 返回列表 记录表格筛选状态
    static filterInit(that, query, columns1) {
        for (let i = 0, len = columns1.length; i < len; i++) {
            let item = columns1[i];
            if (item.sortable) {
                if (item.key === query.order_by || item.key === query.order) {
                    that.$set(item, 'sortType', query.sort);
                } else {
                    if (item.hasOwnProperty('sortType')) delete item.sortType;
                }
            }
            if (item.filters) {
                let key = item.filter_key ? item.filter_key : item.key;
                if (query[key] || query[key] === 0) {
                    item.filteredValue = that.$set(item, 'filteredValue', [query[key]]);
                } else {
                    if (item.hasOwnProperty('filteredValue')) delete item.filteredValue;
                }
            }
        }
    }
    // 处理楼层标识（F、L）
    static handleFloorsMarking(obj) {
        if (!obj) return;
        let new_obj = obj.split('F'), // 切割楼层标识
            letter = new_obj[0] ? new_obj[0] : new_obj[1]; // 自动匹配存在的标识
        // if ($.cookie('mall_id') == 2379) {
        if ($.cookie('mall_id') == 3507) {
            return obj.indexOf('F') > -1 ? `L${letter}` : obj;
        } else {
            return obj.indexOf('F') > -1 ? `${letter}F` : obj;
        }
    }

    static jsonSort(array, field, reverse) {
        //数组长度小于2 或 没有指定排序字段 或 不是json格式数据
        if (array.length < 2 || !field || typeof array[0] !== 'object') return array;
        //数字类型排序
        if (typeof array[0][field] === 'number') {
            array.sort(function (x, y) {
                return x[field] - y[field];
            });
        }
        //字符串类型排序
        if (typeof array[0][field] === 'string') {
            array.sort(function (x, y) {
                return x[field].localeCompare(y[field]);
            });
        }
        //倒序
        if (reverse) {
            array.reverse();
        }
        return array;
    }
}
